import React from 'react'

import styles from './roof-SVG.module.scss'

const RoofSVG = () => {
    return(
        <svg
            className={styles.roofSVG}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 685.073 180.042"
            >
            <g transform="translate(-125.917 -302)">
                <g data-name="Group 1">
                <path
                    fill="#000000"
                    d="M125.917 481.559c9.841-7.135 19.653-14.3 29.533-21.4 10.491-7.543 21.061-15.018 31.572-22.544q26.67-19.1 53.308-38.224c13.245-9.511 26.532-18.988 39.631-28.622 2.608-1.918 4.191-2.053 6.9-.122 23.724 16.908 47.6 33.683 71.338 50.577 2.088 1.486 3.365.944 5.063-.151q16.5-10.631 33.041-21.215 45.995-29.55 91.98-59.109Q517.446 322 546.561 303.2c2.3-1.486 4.036-1.72 6.525-.008q29.551 20.32 59.308 40.457 18.378 12.516 36.761 25.027c.773.525 1.608 1.419 2.687 1.041 1.157-.4.614-1.5.62-2.279.045-5.958.14-11.918-.025-17.874-.072-2.623 1.245-3.461 4.464-3.437 12.1.09 24.212.123 36.315-.018 3.788-.044 5.112.925 5.086 3.951-.143 16.1-.01 32.2-.125 48.295-.018 2.5.837 4.274 3.28 5.968q31.4 21.77 62.617 43.708 22.647 15.838 45.275 31.693c.479.336.892.729 1.641 1.347a4.232 4.232 0 01-3.356-.953q-60.931-34.1-121.827-68.235-49.993-28.035-99.945-56.116c-10.817-6.076-21.661-12.122-32.4-18.288-2.56-1.47-4.327-1.518-6.913.154Q496.514 370 446.291 402.181q-34.576 22.213-69.194 44.391c-4.426 2.839-8.956 5.587-13.239 8.554-2.41 1.671-4.268 1.549-6.539-.074q-17.254-12.339-34.619-24.584c-12.044-8.534-24.145-17.018-36.107-25.622-2.419-1.739-4.283-1.682-6.936-.334q-61.467 31.238-123.032 62.36-13.914 7.049-27.795 14.141c-.764.388-1.6.688-2.4 1.029z"
                    data-name="Path 1"
                ></path>
                </g>
            </g>
        </svg>
    )
}

export default RoofSVG